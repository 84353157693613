// Core
import { FormattedMessage } from 'react-intl';

// UI
import { FormInput } from 'components/Form/FormInput';
import SortMenu from '../SortMenu/SortMenu';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';

// Icons
import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';

// Styles
import styles from '../StatisticByTerritoriesSectionCombined.module.css';

const InputsWrapper = ({
	changeField,
	errors,
	statisticData,
	setStatisticData,
	setIsFilterModalActive,
	isFiltersActive,
	isAdmin,
	isCopyrightingStatisticsEnabled,
	accountId,
	accountOutlets,
}) => {
	const MIN_DATE = '2020-01-01';

	return (
		<div className={styles.inputsWrapper}>
			<div>
				<div className={styles.inputStartDate}>
					<FormInput
						className={styles.date}
						type={'muiInputDate_v3'}
						defaultValue={true}
						name={'start_date'}
						onChange={changeField}
						minDate={Date.parse(MIN_DATE)}
						maxDate={Date.parse(statisticData.end_date)}
						errors={errors}
						data={statisticData}
						label={<FormattedMessage id={'rod.statistic.start-date'} />}
					/>
					{Object.keys(errors).includes('start_date') && (
						<div className={styles.error}>
							<span className={styles.errorText}>
								{errors['start_date'] === 'invalid date' ? (
									<FormattedMessage id={'rod.admin.invalid-date'} />
								) : (
									<FormattedMessage id={'rod.error.statistic.minDate'} />
								)}
								{errors['start_date'] === 'minDate' && MIN_DATE}
							</span>
						</div>
					)}
				</div>
				<div className={styles.inputDate}>
					<FormInput
						className={styles.date}
						type={'muiInputDate_v3'}
						defaultValue={true}
						name={'end_date'}
						onChange={changeField}
						minDate={Date.parse(statisticData.start_date)}
						maxDate={Date.now()}
						errors={errors}
						data={statisticData}
						label={<FormattedMessage id={'rod.statistic.end-date'} />}
					/>
					{Object.keys(errors).includes('end_date') && (
						<div className={styles.error}>
							<span className={styles.errorText}>
								{errors['end_date'] === 'invalid date' ? (
									<FormattedMessage id={'rod.admin.invalid-date'} />
								) : (
									''
								)}
							</span>
						</div>
					)}
				</div>
			</div>
			<div>
				<div
					onClick={() => setIsFilterModalActive(true)}
					className={styles.statistics_filter__item}
				>
					<img src={!isFiltersActive() ? filter_svg : filter_dot_svg} alt="" />
					<FormattedMessage id={'rod.admin.reports.filters'} />
				</div>
				<SortMenu
					sort={statisticData.sort}
					handleSortChange={(sortValue) => {
						setStatisticData({
							...statisticData,
							sort: sortValue,
							page: 1,
						});
					}}
				/>
				{!isCopyrightingStatisticsEnabled && (
					<StatisticDownloadBtn
						accountId={accountId}
						statisticData={statisticData}
						accountOutlets={accountOutlets}
						isAdmin={isAdmin}
						tracksIds={
							statisticData.trackSearchValue
								? statisticData.tracksIds
								: statisticData.checkedAllTracks
								? []
								: statisticData.tracksIds
						}
						v2
					/>
				)}
			</div>
		</div>
	);
};

export default InputsWrapper;
