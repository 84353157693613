// Core
import React, { useRef, useEffect } from 'react';

// UI
import TextareaAutosize from '@mui/base/TextareaAutosize';

// Styles
import s from './MuiTextareaAutosizeV2.module.css';

const containsHtmlTags = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

const Textarea = ({
	placeholder,
	value,
	onChange,
	errors,
	isTextAreaEnable,
	style,
}) => {
	const editableRef = useRef(null);

	useEffect(() => {
		if (editableRef.current) {
			editableRef.current.innerHTML = value;
		}
	}, [value]);

	const handleInput = (e) => {
		const newValue = e.target?.innerHTML;
		onChange('lyrics', newValue);
	};

	return (
		<>
			{containsHtmlTags(value) ? (
				<div style={{ ...style, position: 'relative' }}>
					<div
						ref={editableRef}
						className={errors ? s.TextareaWithError : s.Textarea}
						contentEditable={isTextAreaEnable}
						onInput={handleInput}
						style={{
							whiteSpace: 'pre-wrap',
							...style,
						}}
						placeholder={placeholder}
						dangerouslySetInnerHTML={{ __html: value }}
					/>
				</div>
			) : (
				<TextareaAutosize
					aria-label="lyrics"
					placeholder={placeholder}
					className={errors ? s.TextareaWithError : s.Textarea}
					onChange={onChange}
					value={value}
					disabled={!isTextAreaEnable}
					style={style}
				/>
			)}
		</>
	);
};

export default Textarea;
