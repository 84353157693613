// Core
import { useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// Utils
import { perPageVariants } from '../../utils/perPageVariants';

// UI
import { Pagination } from 'material-design/components';
import { FormInput } from 'components/Form/FormInput';
import StatisticByTerritoriesBar from '../StatisticByTerritoriesBar/StatisticByTerritoriesBar';

// Styles
import styles from '../StatisticByTerritoriesSectionCombined.module.css';

const ColumnarBar = ({
	chartRef,
	amountTitle,
	accountColumnar,
	statisticData,
  setStatisticData,
	countries,
  startEndDates,
  mapTotalPages,
  setPaginationState,
  changeField,
  errors
}) => {
	const { lang } = useContext(LangContext);

	return (
		<div ref={chartRef} className={styles['statistics_bar-chart']}>
			<div className={styles.statistic__mainContentHeaderInfo}>
				<div className={styles.statistic__mainContentHeaderInfo_title}>
					<FormattedMessage id={'rod.statistic.chart-title'} />
				</div>
				<div className={styles.statistic__mainContentHeaderInfo_amount}>
					{amountTitle && <FormattedMessage id={`${amountTitle}`} />}
				</div>
			</div>
			<StatisticByTerritoriesBar
				data={accountColumnar}
				limit={statisticData.per_page}
				allCountries={countries}
				lang={lang}
				sort={statisticData.sort}
				handleSelectCountry={(country) => {
					statisticData.countries = [country];
					statisticData.page = 1;
					setStatisticData({ ...statisticData });
				}}
				dates={{
					startDate: startEndDates[0],
					endDate: startEndDates[1],
				}}
			/>
			<div className={styles['statistics_bar-chart__controls']}>
				{mapTotalPages > 10 && (
					<Pagination
						page={statisticData.page}
						total={mapTotalPages}
						paginate={(pageNumber) => {
							statisticData.page = pageNumber;
							setPaginationState((prev) => ({
								...prev,
								isPaginationPressed: true,
							}));
							setStatisticData({ ...statisticData });
						}}
						items_on_page={statisticData.per_page}
					/>
				)}
				<div className={styles['statistics_bar-chart__controls-pages']}>
					<span>
						<FormattedHTMLMessage id={'rod.statistic.per-page'} />
					</span>
					<FormInput
						type={'MuiSelectCurrencies'}
						name={'per_page'}
						onChange={changeField}
						errors={errors}
						theme="grayscale"
						defaultValue={true}
						data={statisticData}
						label={''}
						items={perPageVariants}
					/>
				</div>
			</div>
		</div>
	);
};

export default ColumnarBar;
