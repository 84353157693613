// Core
import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Services
import { withAuth, withLang, withRoot } from 'hocs';
import update from 'immutability-helper';
import DOMPurify from 'dompurify';
import { getHelpMessage } from '../../../../../messages/helper';
import { compose } from 'recompose';

// UI
import { Loading } from 'components';
import { Bullet } from 'components/Bullet';
import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';

// Styles
import styles from 'pages/account/Create/steps/02-Confirm/Confirm.module.css';

class Confirm extends PureComponent {
	static propTypes = {};

	state = {
		loading: true,
		roles: [],
	};

	componentDidMount() {
		const {
			UIContext: { showTitle, showBackNavi },
			isAddRole,
		} = this.props;

		if (isAddRole) {
			showTitle('rod.account.add_role');
			showBackNavi(`/treaties`);
		}
		this.update();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}
	}

	componentWillUnmount() {
		const {
			UIContext: { hideTitle, hideBackNavi },
			isAddRole,
		} = this.props;

		if (isAddRole) {
			hideTitle();
			hideBackNavi();
		}
	}

	update() {
		const {
			rootContext: { getRolesForAddNew },
			authContext: {
				changeWidget,
				getAccountCompletionStep,
				setIsAccountLoading,
			},
			completionStep,
			isAddRole,
			id,
		} = this.props;
		const accId = id ?? localStorage.getItem('accountId');
		setIsAccountLoading(true);
		changeWidget();
		getAccountCompletionStep(completionStep);
		if (accId) {
			getRolesForAddNew(accId)
				.then((res) => {
					const rolesWithChecked = res.map((item) => {
						if (item.selected && item.signed) {
							return { ...item, checked: true };
						}
						return item;
					});

					this.setState({
						roles: isAddRole ? res : rolesWithChecked,
						loading: false,
					});
					setIsAccountLoading(false);
				})
				.catch((err) => {
					console.error(err.errors);
					setIsAccountLoading(false);
				});
		}
	}

	nextHandler = () => {
		const { isAddRole } = this.props;

		if (typeof this.props.onChange === 'function') {
			if (isAddRole) {
				const scopeIds = this.state.roles
					.filter((role) => role.checked || role.selected)
					.map((role) => role.id);

				this.props.onChange(scopeIds);
			} else {
				this.props.onChange(
					this.state.roles.filter((role) => role.checked).map((role) => role.id)
				);
			}
		}
	};

	roleSelectHandler = (id) => (event) => {
		this.setState(
			update(this.state, {
				roles: {
					[this.state.roles.findIndex((role) => role.id === id)]: {
						checked: { $set: event.target.checked },
						selected: { $set: event.target.checked },
					},
				},
			})
		);
	};

	renderForm() {
		const {
			UIContext: { showModal },
		} = this.props;

		const lang = localStorage.getItem('lang');

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'account_your_role')
		);

		return (
			<Col sm={6} offset={{ sm: 1 }} style={{ padding: 0 }}>
				<div className={styles.Form}>
					<div
						className={styles.labelWrapper}
						style={{ 'margin-bottom': '50px' }}
					>
						<InfoButton
							className={styles.infoButton}
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.create.confirm_role.form.header'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
								className={styles.Header}
							/>
						</InfoButton>
					</div>
					{this.renderFields()}
					{this.renderControls()}
				</div>
			</Col>
		);
	}

	renderFields() {
		const { roles } = this.state;

		return (
			<div>
				{roles.map((role) => (
					<Checkbox
						key={role.id}
						text={
							<FormattedMessage id={`rod.ref.roles.${role.keyword}.title`} />
						}
						onChange={this.roleSelectHandler(role.id)}
						className={styles.Field}
						isLocked={false}
						checked={role.selected || role.checked}
					/>
				))}
			</div>
		);
	}

	renderSummary() {
		const { roles } = this.state;

		return (
			<Col sm={5} className={styles.Summary}>
				<div className="content">
					<Label
						text={
							<FormattedMessage
								id={'rod.account.create.confirm_role.summary.header'}
							/>
						}
						font="--gilroy-Medium-32"
						black
						className={styles.Header}
					/>
					{roles.map((role) => {
						switch (true) {
							case role.signed:
							case role.checked:
							case role.selected:
								return (
									<Bullet key={role.id} className={styles.Bullet}>
										<FormattedMessage
											id={`rod.ref.roles.${role.keyword}.summary`}
										/>
									</Bullet>
								);
							default:
								break;
						}
					})}
				</div>
			</Col>
		);
	}

	render() {
		const { loading } = this.state;

		if (loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<Container fluid className={styles.Page}>
				<Row style={{ height: '100%' }}>
					{this.renderForm()}
					{this.renderSummary()}
				</Row>
			</Container>
		);
	}

	isRoleChecked = () => {
		const role = this.state.roles.find((item) => item.checked);
		return role;
	};

	renderControls() {
		return (
			<div className={styles.Controls}>
				<Button
					text={<FormattedMessage id={'rod.action.next'} />}
					variant={'primary'}
					onClick={this.nextHandler}
					disabled={!this.isRoleChecked()}
				/>
			</div>
		);
	}
}

export default compose(withRoot, withAuth, withLang)(Confirm);
