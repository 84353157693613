// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import lscache from 'lscache';

// Services
import { accounts } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// Context
import { UIContext } from 'contexts/UIContext';
import { LangContext } from 'contexts/LangContext';

// Utils
import { getGenres } from 'utils';
import { getRenderedValue } from 'utils';

// Icons
import play_button from 'images/play_button.svg';
import stop_button from 'images/stop_button.svg';

// UI
import ConfirmDistributions from '../ConfirmDistributions/ConfirmDistributions';
import { DropZone } from 'components/Upload/DropZone';

// Styles
import s from '../Confirm.module.css';

const Body = ({
	personal,
	country,
	cover,
	loading,
	handleOnLoad,
	handleFileUpload,
	emptyTrackList,
	recommendedOutlets,
}) => {
	const { play, handlePlay, audioId, handlePlayStop } = useContext(UIContext);
	const { lang } = useContext(LangContext);

	const [genresArr, setGenresArr] = useState([]);

	useEffect(() => {
		let genresCache = lscache.get('genresCache');

		if (genresCache) {
			if (lang === 'ru') {
				genresCache = genresCache.sort((a, b) =>
					a.title_ru > b.title_ru ? 1 : -1
				);
			} else {
				genresCache = genresCache.sort((a, b) =>
					a.title_en > b.title_en ? 1 : -1
				);
			}
			setGenresArr(genresCache);
		} else {
			accounts
				.getGenres()
				.then((res) => {
					res = res.data.data;
					setCache('genres', 'genresCache', res);
					if (lang === 'ru') {
						res = res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
					} else {
						res = res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
					}
					setGenresArr(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={s.body}>
			<div className={s.section__information}>
				<div className={s.info__table}>
					<span className={s.title}>
						<FormattedMessage
							id={'rod.release.create.step.submit.body_title'}
						/>
					</span>
					<div className={s.table}>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.artist'}
								/>
							</span>
							<span className={s.item__text}>{personal.performers}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.subtitle'}
								/>
							</span>
							<span className={s.item__text}>{personal?.subtitle || '-'}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage id={'rod.release.create.step.submit.label'} />
							</span>
							<span className={s.item__text}>
								{personal?.label ? getRenderedValue(personal.label) : '-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.catalog_number'}
								/>
							</span>
							<span className={s.item__text}>
								{personal.catalog_number ||
									(personal.generate_catalog_number && (
										<FormattedMessage
											id={'rod.release.create.step.submit.assign_automatically'}
										/>
									)) ||
									'-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>GRID</span>
							<span className={s.item__text}>
								{personal.grid?.replace(
									/(\w{2})(\w{5})(\w{10})(\w{1})/,
									'$1-$2-$3-$4'
								) ||
									(personal.generate_grid && (
										<FormattedMessage
											id={'rod.release.create.step.submit.assign_automatically'}
										/>
									)) ||
									'-'}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>EAN/UPC</span>
							<span className={s.item__text}>
								{personal.generate_ean ? (
									<FormattedMessage
										id={'rod.release.create.step.submit.assign_automatically'}
									/>
								) : (
									personal.ean
								)}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.genres'}
								/>
							</span>
							<span className={`${s.item__text} ${s.item_genres}`}>
								{personal.genres && getGenres(lang, genresArr, personal.genres)}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.create_date'}
								/>
							</span>
							<span className={s.item__text}>{personal.created_date}</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.country_name.review'}
								/>
							</span>
							<span className={s.item__text}>
								{lang === 'ru' ? country?.title_ru : country?.title_en}
							</span>
						</div>
					</div>
				</div>

				<div className={s.DropZone__wrapper}>
					{cover || loading ? (
						<div
							className={s.Preview}
							style={loading ? { border: '1px solid var(--color-black)' } : {}}
						>
							<img
								className={loading ? s.loader : null}
								src={personal?.cover?.url || cover}
								onLoad={handleOnLoad}
								alt=""
							/>
							{loading && <div className={s.preLoader} />}
						</div>
					) : (
						<>
							<DropZone
								multiple={false}
								accept=".jpg, .jpeg, .png, .tiff"
								imgType={2}
								classStyles={s.DropZone}
								dropzoneId={'rod.release.create.step.cover.upload'}
								onUpload={handleFileUpload}
								kb
								maxSize={1024 * 1024 * 100}
							/>
							<p className={s.error}>
								<FormattedMessage
									id={'rod.release_create.error.cover_required'}
								/>
							</p>
						</>
					)}
				</div>
			</div>
			{!loading && (
				<div className={s.info}>
					<div className={s.recordings__wrapper}>
						<span className={s.lh__title}>
							<FormattedMessage
								id={'rod.release.create.step.submit.recordings'}
							/>
						</span>

						<div className={s.recordings}>
							{personal?.tracks && personal?.tracks.length !== 0 ? (
								personal.tracks.map((item, index) => (
									<div className={s.recordings__item} key={item.id}>
										<span>{index + 1}</span>
										<span>{item?.title}</span>
										<span></span>
										{(audioId === item.id && play) ||
										(audioId === item.file?.id && play) ? (
											<img
												src={stop_button}
												className={s.play}
												onClick={handlePlayStop}
												alt=""
											/>
										) : (
											<img
												src={play_button}
												className={s.play}
												onClick={() =>
													handlePlay(
														index,
														item.file,
														item?.title ?? item.file?.client_name
													)
												}
												alt=""
											/>
										)}
									</div>
								))
							) : (
								<>
									<div className={s.error}>
										<FormattedMessage
											id={'rod.release_create.error.track_required'}
										/>
									</div>
									<span
										className={s.errorButton}
										onClick={() => emptyTrackList()}
									>
										<FormattedMessage
											id={'rod.release.create.step.submit.upload'}
										/>
									</span>
								</>
							)}
						</div>
					</div>

					<ConfirmDistributions
						personal={personal}
						recommendedOutlets={recommendedOutlets}
					/>
				</div>
			)}
		</div>
	);
};

export default Body;
