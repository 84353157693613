// Core
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { compose } from 'recompose';

// Context
import { withUI, withAuth, withRoot } from 'hocs';
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { setCache } from 'services/cacheHelper/setCache';
import { checkRangeInOneYear } from '../utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../utils/getStartEndDatesForOneYear';
import { getDateInYYYYMMDD } from '../StatisticByDatesSection/utils/getDateInYYYYMMDD';

// Constants
import { TIME_CONSTANT } from 'constants/timeConstants';

// UI
import StatisticHeader from 'material-design/Statistic/StatisticHeader/StatisticHeader';
import ColumnarBar from './ColumnarBar/ColumnarBar';
import { Pagination } from 'material-design/components';
import { Loading, TopTracksSkeleton } from 'components';
import { StatisticByTerritoriesChart } from './StatisticByTerritoriesChart';
import StatisticFilterModal from '../StatisticFilterModal/StatisticFilterModal';
import NoDataStat from 'components/NoDataStats/NoDataStats';
import { FullScreenModalConfirm } from 'containers/Modal';
import TopTracksTable from './TopTracksTable/TopTracksTable';
import DataLoadingError from '../DataLoadingError/DataLoadingError';
import InputsWrapper from './InputsWrapper/InputsWrapper';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticByTerritoriesSectionCombined.module.css';

const StatisticByTerritoriesSectionCombined = (props) => {
	const chartRef = useRef(null);

	const [statisticData, setStatisticData] = useState({
		start_date: new Date(
			new Date().getTime() - TIME_CONSTANT * 32
		).toISOString(),
		end_date: new Date(new Date().getTime() - TIME_CONSTANT * 2).toISOString(),
		tracksIds: [],
		outlets: [],
		countries: [],
		per_page: 10,
		sort: 'desc',
		page: 1,
		checkedAllTracks: false,
		trackSearchValue: '',
	});
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [helper, setHelper] = useState(
		localStorage.getItem('helper') !== 'showed'
	);
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);
	const [mapTotalPages, setMapTotalPages] = useState(0);
	const [amountTitle, setAmountTitle] = useState('');
	const [topTracks, setTopTracks] = useState([]);
	const [outletCodes, setOutletCodes] = useState([]);
	const [countries, setCountries] = useState([]);
	const [initialOutlets, setInitialOutlets] = useState([]);
	const [checkedTopTrack, setCkeckedTopTrack] = useState(null);
	const [errors, setErrors] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isReqLive, setIsReqLive] = useState(false);
	const [isInitialData, setIsInitialData] = useState(true);
	const [accountMap, setAccountMap] = useState([]);
	const [accountColumnar, setAccountColumnar] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	const [mapDataError, setMapDataError] = useState(false);
	const [chartMode, setChartMode] = useState(props.chartMode ?? 'phono');

	const [paginationState, setPaginationState] = useState({
		page: 1,
		total: 0,
		isPaginationPressed: false,
	});

	const [loading, setLoading] = useState(true);
	const [dataLoading, setDataLoading] = useState(true);
	const [pageLoading, setPageLoading] = useState(false);
	const [outletsFetched, setOutletsFetched] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);

	const isLoading = loading || dataLoading;

	const {
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticAuthorTopTracks,
			getAccountStatisticsMap,
			getAccountStatisticsAuthorMap,
			getAccountStatisticAuthorMapByDate,
			getAccountStatisticMapByDate,
		},
		adminAccountId,
	} = props;

	const {
		showModal,
		isCopyrightingStatisticsEnabled,
		isStatisticsColumnarCountries,
		isStatisticsMapCountries,
	} = useContext(UIContext);
	const { lang } = useContext(LangContext);

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	const columnarRequest =
		chartMode === 'compo'
			? getAccountStatisticsAuthorMap
			: getAccountStatisticsMap;

	const mapRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorMapByDate
			: getAccountStatisticMapByDate;

	const topTracksRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorTopTracks
			: getAccountStatisticTopTracks;

	const fetchStatisticsData = async () => {
		setDataLoading(true);

		try {
			const outletsRes = await getAccountStatisticOutlets();
			const accountOutlets = outletsRes.data.data;
			setAccountOutlets(accountOutlets);
			setOutletsFetched(true);

			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = accountOutlets.map((item) => item.code);

			// Prepare an array of promises based on which statistics are required
			let requests = [];

			if (isStatisticsColumnarCountries && accountOutlets.length) {
				const columnarPromise = columnarRequest(
					accountId,
					statisticData.outlets.map((item) => item.code),
					startEndDates[0],
					startEndDates[1],
					statisticData.tracksIds,
					statisticData.per_page,
					statisticData.page,
					statisticData.sort,
					statisticData.countries.map((item) => item.code)
				).then(({ data }) => {
					const responseData = data.data;
					setMapTotalPages(responseData.last_page * statisticData.per_page);
					setAccountColumnar(responseData.global);
					if (responseData.global?.length) {
						let maxAuditions = Number(responseData.global[0].stat_count);
						if (
							statisticData.sort !== 'desc' &&
							responseData.global[responseData.global.length - 1]
						) {
							maxAuditions = Number(
								responseData.global[responseData.global.length - 1].stat_count
							);
						}
						if (maxAuditions <= 10000) {
							setAmountTitle('rod.statistic.chart-amount-units');
						} else if (maxAuditions > 10000) {
							setAmountTitle('rod.statistic.chart-amount-thousands');
						}
					}
				});
				requests.push(columnarPromise);
			}

			if (isStatisticsMapCountries && accountOutlets.length) {
				const sortOutlets = outletsCodes.sort();
				const mapHash = `${sortOutlets.join('*')}*${startEndDates.join('*')}*${
					isAllTracks ? 'all' : tracksIds.join('*')
				}*map*${accountId}`;
				const mapPromise = mapRequest(
					isAdmin,
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					statisticData.tracksIds.length && !statisticData.checkedAllTracks
						? statisticData.tracksIds
						: [],
					statisticData.tracksIds.length && !statisticData.checkedAllTracks
						? false
						: true
				).then((res) => {
					const responseData = res.data.data;
					if (isReqLive) {
						setAccountMap(res.global);
					} else {
						setCache('statisticMapCache', mapHash, responseData);
						setAccountMap(responseData.global);
					}
				});
				requests.push(mapPromise);
			}

			await Promise.all(requests);
			setDataLoaded(true);
		} catch (error) {
			console.error('Error fetching data:', error);
			setDataLoaded(true);
		} finally {
			setDataLoading(false);
		}
	};

	useEffect(() => {
		fetchStatisticsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		statisticData.start_date,
		statisticData.end_date,
		statisticData.tracksIds,
		statisticData.outlets,
		statisticData.countries,
		statisticData.per_page,
		statisticData.sort,
		statisticData.page,
		statisticData.checkedAllTracks,
		chartMode,
	]);

	useEffect(() => {
		if (
			outletsFetched &&
			accountOutlets.length &&
			(tracksIds.length || statisticData.countries.length) &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const outletsCodes = outlets.map((outlet) => outlet.code);

			//Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${isAllTracks ? 'all' : tracksIds.join('*')}*topTracks*${accountId}`;

			setPaginationState((prev) => ({
				...prev,
				page: 1,
				isPaginationPressed: false,
			}));
			const countriesList = statisticData.countries.map((item) => item.code);
			topTracksRequest(
				isAdmin,
				accountId,
				paginationState.page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				[],
				countriesList
			).then((res) => {
				res = res.data.data;
				if (isReqLive) {
					setTopTracks(res.recordings);
					setPaginationState((prev) => ({ ...prev, total: res.total }));
					setPageLoading(false);
				} else {
					setCache('statisticTopTracksCache', topTracksHash, res);
					setTopTracks(res.recordings);
					setPaginationState((prev) => ({ ...prev, total: res.total }));
					setPageLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountOutlets, statisticData, tracksIds, chartMode]);

	useEffect(() => {
		if (
			outletsFetched &&
			accountOutlets.length &&
			paginationState.isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const countriesList = statisticData.countries.map((item) => item.code);

			getAccountStatisticTopTracks(
				isAdmin,
				accountId,
				paginationState.page,
				startEndDates[0],
				startEndDates[1],
				outletsCodes,
				10,
				statisticData.checkedAllTracks ? [] : statisticData.tracksIds,
				statisticData.countries.map((item) => item.code),
				[],
				countriesList
			).then((res) => {
				res = res.data.data;

				if (isReqLive) {
					setTopTracks(res.recordings);
					setPaginationState((prev) => ({ ...prev, total: res.total }));
					setPageLoading(false);
				} else {
					setTopTracks(res.recordings);
					setPaginationState((prev) => ({ ...prev, total: res.total }));
					setPageLoading(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationState.isPaginationPressed, paginationState.page]);

	const closeModal = () => {
		setShowRangeModal(false);
	};

	const changeField = (field) => (e) => {
		switch (field) {
			case 'per_page': {
				statisticData[field] = e.id;
				statisticData.page = 1;
				setStatisticData({ ...statisticData });
				chartRef.current.scrollIntoView({ behavior: 'smooth' });
				break;
			}
			case 'start_date':
				statisticData.page = 1;
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) >= Date.parse(MIN_DATE) &&
						Date.parse(e) < Date.parse(statisticData['end_date'])
					) {
						checkRangeInOneYear(statisticData, setShowRangeModal, showModal, e);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - TIME_CONSTANT * 32)
					);
					setStatisticData({ ...statisticData });
				}

				break;
			case 'end_date':
				statisticData.page = 1;
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (
						Date.parse(e) > Date.parse(statisticData['start_date']) &&
						Date.parse(e) <= MAX_DATE
					) {
						checkRangeInOneYear(
							statisticData,
							setShowRangeModal,
							showModal,
							statisticData.start_date,
							e
						);
						statisticData[field] = e;
						setStatisticData({ ...statisticData });
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - TIME_CONSTANT * 2)
					);
					setStatisticData({ ...statisticData });
				}

				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;

			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
		if (statisticData['outlets'].length > 0 && isInitialData) {
			setIsInitialData(false);
		} else if (statisticData['outlets'].length === 0 && !isInitialData) {
			setIsInitialData(true);
		}
	};

	const handleCheckedTracks = (tracks, isAll) => {
		if (
			(tracks.length !== tracksIds.length && tracks.length !== 0) ||
			tracks.length === 1
		) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
		}
	};

	const handleCheckedOutlets = (outlets, initial = false) => {
		const codes = outlets.map((item) => item.code);

		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData['outlets'] = outlets;
			setStatisticData({ ...statisticData });
		}
		if (initial) {
			setInitialOutlets(outlets);
		}
	};

	const handleCheckedTopTrack = (track) => {
		if (track) {
			setCkeckedTopTrack(track);
			setStatisticData({
				...statisticData,
				tracksIds: [track.id],
				checkedAllTracks: false,
				page: 1,
			});
		} else {
			setCkeckedTopTrack(null);
			setStatisticData({ ...statisticData, tracksIds: [] });
		}
	};

	// Memoize calculated start and end dates
	const startEndDates = useMemo(() => {
		return getStartEndDatesForOneYear(statisticData, isAdmin);
	}, [statisticData, isAdmin]);

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	const isFiltersActive = () => {
		if (
			statisticData.outlets.length !== outletCodes.length ||
			statisticData.tracksIds.length ||
			statisticData.countries.length
		) {
			return true;
		}
		return false;
	};

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}
			{isCopyrightingStatisticsEnabled && (
				<StatisticHeader
					chartMode={chartMode}
					setChartMode={setChartMode}
					accountId={accountId}
					statisticData={statisticData}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					trackSearchValue={statisticData.trackSearchValue}
					tracksIds={statisticData.tracksIds}
					isAllTracks={statisticData.checkedAllTracks}
					v2
					statType="byterritory"
				/>
			)}

			<div className={styles.statisticByTerritories__mainContent}>
				<InputsWrapper
					changeField={changeField}
					errors={errors}
					statisticData={statisticData}
					setStatisticData={setStatisticData}
					setIsFilterModalActive={setIsFilterModalActive}
					isFiltersActive={isFiltersActive}
					isAdmin={isAdmin}
					isCopyrightingStatisticsEnabled={isCopyrightingStatisticsEnabled}
					accountId={accountId}
					accountOutlets={accountOutlets}
				/>

				{isLoading || !dataLoaded ? (
					<Loading className={styles.loader} />
				) : mapDataError ? (
					<DataLoadingError />
				) : !accountColumnar.length && !accountMap.length ? (
					<NoDataStat />
				) : (
					<>
						{accountColumnar.length > 0 && isStatisticsColumnarCountries && (
							<ColumnarBar
								chartRef={chartRef}
								amountTitle={amountTitle}
								accountColumnar={accountColumnar}
								statisticData={statisticData}
								setStatisticData={setStatisticData}
								countries={countries}
								startEndDates={startEndDates}
								mapTotalPages={mapTotalPages}
								setPaginationState={setPaginationState}
								changeField={changeField}
								errors={errors}
							/>
						)}

						{accountMap.length > 0 && isStatisticsMapCountries && (
							<StatisticByTerritoriesChart
								data={accountMap}
								selectedOutlets={
									isInitialData ? accountOutlets : statisticData.outlets
								}
							/>
						)}

						<>
							<div className={styles.topTracksTitle}>
								<span>
									<FormattedHTMLMessage id={'rod.statistic.top_tracks'} />
								</span>
							</div>
							{pageLoading ? (
								<TopTracksSkeleton />
							) : (
								<>
									{topTracks && topTracks.length > 0 && (
										<>
											<TopTracksTable
												topTracks={topTracks}
												handleCheckedTopTrack={handleCheckedTopTrack}
												page={paginationState.page}
												chartMode={chartMode}
												statisticData={statisticData}
												isAdmin={isAdmin}
												outlets={accountOutlets}
												accountId={accountId}
											/>
											{paginationState.total > 10 && (
												<Pagination
													page={paginationState.page}
													total={paginationState.total}
													paginate={(pageNumber) => {
														setPaginationState((prev) => ({
															...prev,
															page: pageNumber,
															isPaginationPressed: true,
														}));
														setPageLoading(true);
													}}
													items_on_page={10}
												/>
											)}
										</>
									)}
								</>
							)}
						</>
					</>
				)}

				{showRangeModal && !props.main && (
					<FullScreenModalConfirm
						title={<FormattedMessage id={'rod.modal.statistic_title'} />}
						text={<FormattedMessage id={'rod.modal.statistic_text'} />}
						confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
						onClose={closeModal}
					/>
				)}
				<StatisticFilterModal
					lang={lang}
					isAdmin={isAdmin}
					accountId={accountId}
					statisticData={statisticData}
					errors={errors}
					startEndDates={startEndDates}
					active={isFilterModalActive}
					setLoading={setLoading}
					setActive={() => setIsFilterModalActive(false)}
					checkedTopTrack={checkedTopTrack}
					setInitialData={(tracks, outlets, countries) => {
						handleCheckedTracks(tracks, true);
						handleCheckedOutlets(outlets, true);
						setCountries(countries);
					}}
					handleSetFilters={(filtersData, isAllChecked, searchValue) => {
						Object.keys(filtersData).forEach((filterKey) => {
							if (filtersData[filterKey]) {
								statisticData[filterKey] = filtersData[filterKey];
							}
						});
						if (isAllChecked) {
							statisticData.tracksIds = [];
						}
						if (searchValue) {
							statisticData.trackSearchValue = searchValue;
						}

						statisticData.checkedAllTracks = isAllChecked;
						statisticData.page = 1;
						setStatisticData({ ...statisticData });
					}}
					handleClearAllFilters={() => {
						setStatisticData({
							...statisticData,
							outlets: initialOutlets,
							tracksIds: [],
							countries: [],
							page: 1,
						});
					}}
					chartMode={chartMode}
				/>
			</div>
		</>
	);
};

export default compose(
	withUI,
	withAuth,
	withRoot
)(StatisticByTerritoriesSectionCombined);
